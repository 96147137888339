import {Box, Colors, Icon} from '@dagster-io/ui-components';
import Cookies from 'js-cookie';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import isEmail from 'validator/lib/isEmail';

import {Input, SubmitButton} from './button';
import {useAnalytics} from '../contexts/analytics';
import {useRecaptcha} from '../hooks/useRecaptcha';

type Props = {
  buttonText: string;
  submitMessage: string;
  submitUrl: string;
  recaptchaNotice?: JSX.Element;
};
export function EmailSignIn({buttonText, submitMessage, submitUrl, recaptchaNotice}: Props) {
  const {execute} = useRecaptcha();
  const analytics = useAnalytics();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState<string | null>(null);
  const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);

  const [params] = useSearchParams();

  const emailRef = React.useRef<HTMLInputElement>(null);
  const submit = async () => {
    setErrors(null);
    const input = emailRef.current;
    if (input) {
      if (isEmail(email)) {
        const email = input.value;
        setSubmitting(true);
        setIsInvalidEmail(false);
        const recaptcha_token = await execute('signup');

        const url = new URL(window.location.origin + submitUrl);
        params.append('recaptcha_token', recaptcha_token);
        url.search = params.toString();

        const fetchUrl = url.toString();

        // Extract an anonymous ID from Segment, if any. This allows us to connect the
        // newly created user with their anonymous tracked behavior.
        const analyticsUser = await analytics?.user();

        const response = await fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': Cookies.get('X-CSRF-TOKEN') ?? '',
          },
          body: JSON.stringify({
            anonymous_id: analyticsUser ? analyticsUser.anonymousId() : null,
            email,
          }),
        });

        if (response.status === 200) {
          setSubmitted(true);
          setSubmitting(false);
        } else {
          const {error} = await response.json();
          setErrors(error);
          setSubmitting(false);
        }
      } else {
        setIsInvalidEmail(true);
      }
    }
  };

  const [email, setEmail] = React.useState('');
  return (
    <>
      {submitted ? (
        <Msg>
          <Box
            flex={{alignItems: 'center', gap: 8, justifyContent: 'center'}}
            style={{fontSize: '16px'}}
          >
            <Icon name="check_circle" color={Colors.Green700} size={24} />
            {submitMessage}
          </Box>
        </Msg>
      ) : (
        <>
          <Input
            type="email"
            placeholder="Enter your email"
            ref={emailRef}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            invalid={isInvalidEmail || !!errors}
          />
          <SubmitButton
            disabled={submitting || !email}
            onClick={submit}
            intent={submitted ? 'success' : undefined}
            loading={submitting}
            $disabled={submitting || !email}
            alignText="center"
          >
            {buttonText}
          </SubmitButton>
          {recaptchaNotice}
        </>
      )}
      {errors ? <Msg style={{color: Colors.Red500}}>{errors}</Msg> : null}
      {isInvalidEmail ? <Msg invalid={true}>Invalid email</Msg> : null}
    </>
  );
}

const Msg = styled.div<{
  invalid?: boolean;
}>`
  color: ${Colors.Green700};
  margin-top: 18px;
  ${({invalid}) => (invalid ? `color: red;` : ``)}
`;
