import {Box, Colors, Icon} from '@dagster-io/ui-components';
import {useState} from 'react';

const HIDE_NEW_DESIGN_WARNING_KEY = 'dagster-login-hide-new-design-warning';

export function NewDesignWarning() {
  const [hidden, setHidden] = useState(
    () => window.localStorage.getItem(HIDE_NEW_DESIGN_WARNING_KEY) === 'true',
  );

  const handleClick = () => {
    setHidden(true);
    window.localStorage.setItem(HIDE_NEW_DESIGN_WARNING_KEY, 'true');
  };

  if (hidden) {
    return null;
  }

  return (
    <Box
      padding={16}
      background={Colors.White}
      style={{borderRadius: 8, textAlign: 'left'}}
      flex={{direction: 'row', gap: 8, alignItems: 'flex-start'}}
    >
      <Box
        flex={{direction: 'column', gap: 4, alignItems: 'flex-start'}}
        style={{lineHeight: '20px'}}
      >
        <div style={{fontSize: 14, fontWeight: 600}}>New sign in experience coming soon</div>
        <div style={{fontSize: 12}}>
          We&apos;re refreshing the Dagster+ sign-in experience to better match the app. Expect a
          new look in the coming weeks!
        </div>
      </Box>
      <button
        style={{padding: 0, margin: 0, border: 'none', background: 'none', cursor: 'pointer'}}
        onClick={handleClick}
      >
        <Icon name="close" />
      </button>
    </Box>
  );
}
